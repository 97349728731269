@font-face {
  font-family: 'Gotham';
  src: url(./fonts/Gotham-Book.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url(./fonts/Gotham-Medium.otf);
  font-weight: 500;
}

html,
body,
#root {
  height: 100%;
}

* {
  font-family: Gotham, sans-serif !important;
}

body {
  margin: 0;
  background-color: #0D0D0D !important;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

/* CUSTOM SCROLLBAR */
body::-webkit-scrollbar {
  background-color: #0D0D0D;
  width: 16px;
}
body::-webkit-scrollbar-track {
  background-color: #0D0D0D;
}
body::-webkit-scrollbar-thumb {
  background-color: #1E1E1E;
  border-radius: 16px;
  border: 4px solid #0D0D0D;
}
body::-webkit-scrollbar-button {
  display: none;
}